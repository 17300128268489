import { Config } from 'App/config';

import { usePostSession, useMutateData, getCurrentAccount, PostSessionResponse } from '@ecolytiq/js-data-provider';

import { useNavigate } from 'react-router-dom';

import { useCallback, useEffect } from 'react';

import { jwtDecode } from 'jwt-decode';

import {
  setSessionTokenAction,
  setUserIdAction,
  useUserContext,
  setIsLoggedInAction,
  setIsFirstLoginAction,
  useDeps,
} from 'App/context';

import { useCookies } from 'react-cookie';

import { RoutePaths } from 'impactApp/routes/routePaths';

import { appConfig } from 'App/appConfig/appConfig';

import { useTranslation } from 'react-i18next';

import { handleUserLogin } from '../../../App/utils';

export const useSessionData = (code: string) => {
  const { httpClientService, httpClientServiceOAuth } = useDeps();
  const [{ sessionToken }, dispatch] = useUserContext();

  const { i18n } = useTranslation();

  const { REACT_APP_DEMO_API, REACT_APP_MOCK_API, REACT_APP_ECOLYTIQ_KEYCLOAK_CLIENT_ID } = Config.getAll();

  const navigate = useNavigate();

  const { CARD_PAGE, LANDING_PAGE, HOME, SSO_PAGE } = RoutePaths;

  const { REACT_APP_FIRST_LOGIN_COOKIE_NAME, REACT_APP_ACCESS_TOKEN_COOKIE_NAME, REACT_APP_REFRESH_TOKEN_COOKIE_NAME } =
    appConfig;

  const [cookies, setCookie] = useCookies([REACT_APP_FIRST_LOGIN_COOKIE_NAME]);

  const loginAndRedirectToHomePage = useCallback(() => {
    dispatch(setIsLoggedInAction(true));
  }, [dispatch]);

  const { mutate: sessionMutate } = useMutateData<PostSessionResponse>(
    usePostSession(
      httpClientServiceOAuth,
      code,
      REACT_APP_ECOLYTIQ_KEYCLOAK_CLIENT_ID as string,
      window.location.origin,
    ),
    {
      onSuccess: async (sessionDataResponse: any) => {
        const { access_token, refresh_token } = sessionDataResponse;
        dispatch(setSessionTokenAction(access_token));
        const decoded = jwtDecode(access_token);
        const { sub: userId } = decoded;

        if (userId) {
          dispatch(setUserIdAction(userId));
          handleUserLogin(userId);
        }

        httpClientService.removeInterceptor(0);
        const interceptor = httpClientService.setInterceptor(({ headers, ...restRequestConfig }: any) => {
          return {
            ...restRequestConfig,
            headers: {
              ...headers,
              Authorization: `Bearer ${access_token}`,
              'accept-language': i18n.language,
            },
          };
        });

        setCookie(REACT_APP_ACCESS_TOKEN_COOKIE_NAME, access_token, {
          path: '/',
          secure: true,
        });

        setCookie(REACT_APP_REFRESH_TOKEN_COOKIE_NAME, refresh_token, { path: '/', secure: true });

        const firstLoginFunction = getCurrentAccount(httpClientService);
        const account = await firstLoginFunction.get();
        const { onboardingAnswers } = account.data.currentAccount;
        dispatch(setIsLoggedInAction(true));
        const answeredOnboardingAnswers = onboardingAnswers?.filter(
          (question: any) => question.answerId && question.answerId !== null,
        );

        if (
          cookies[REACT_APP_FIRST_LOGIN_COOKIE_NAME] == true ||
          (answeredOnboardingAnswers && answeredOnboardingAnswers?.length > 0)
        ) {
          navigate(HOME);
        } else {
          dispatch(setIsFirstLoginAction(true));
          setCookie(REACT_APP_FIRST_LOGIN_COOKIE_NAME, 'true', {
            secure: true,
          });
          navigate(CARD_PAGE);
        }

        httpClientService.removeInterceptor(interceptor);
      },
      onError: async () => {
        navigate(SSO_PAGE);
      },
    },
  );

  useEffect(() => {
    if (REACT_APP_MOCK_API === 'true') loginAndRedirectToHomePage();
    else if (REACT_APP_DEMO_API === 'true' && !code) navigate('./?code=demo-account');
    else if (!sessionToken && code) sessionMutate();
  }, [
    sessionMutate,
    sessionToken,
    code,
    navigate,
    REACT_APP_DEMO_API,
    REACT_APP_MOCK_API,
    loginAndRedirectToHomePage,
    CARD_PAGE,
    httpClientService,
    httpClientServiceOAuth,
    LANDING_PAGE,
  ]);
};
