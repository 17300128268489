import { Config } from 'App/config';

import { useRefreshSession, useMutateData, PostSessionResponse } from '@ecolytiq/js-data-provider';

import { useNavigate } from 'react-router-dom';

import { useEffect } from 'react';

import { jwtDecode } from 'jwt-decode';

import {
  setSessionTokenAction,
  setUserIdAction,
  useUserContext,
  setIsLoggedInAction,
  useDeps,
  setSessionRefreshTokenAction,
  setSessionLogoutAction,
} from 'App/context';

import { useCookies } from 'react-cookie';

import { RoutePaths } from 'impactApp/routes/routePaths';

import { appConfig } from 'App/appConfig/appConfig';

import { useTranslation } from 'react-i18next';

import { handleUserLogin, handleUserLogout } from '../../utils';

export const useRefreshSessionData = (refreshToken: string) => {
  const { httpClientService, httpClientServiceOAuth } = useDeps();

  const [{ isLoggedIn, sessionRefreshToken }, dispatch] = useUserContext();

  const { REACT_APP_ECOLYTIQ_KEYCLOAK_CLIENT_ID } = Config.getAll();

  const { i18n } = useTranslation();

  const navigate = useNavigate();
  const { SSO_PAGE } = RoutePaths;

  const { REACT_APP_FIRST_LOGIN_COOKIE_NAME, REACT_APP_ACCESS_TOKEN_COOKIE_NAME, REACT_APP_REFRESH_TOKEN_COOKIE_NAME } =
    appConfig;

  const [cookies, setCookie] = useCookies([REACT_APP_FIRST_LOGIN_COOKIE_NAME]);

  const { mutate: sessionMutate } = useMutateData<PostSessionResponse>(
    useRefreshSession(httpClientServiceOAuth, refreshToken, REACT_APP_ECOLYTIQ_KEYCLOAK_CLIENT_ID as string),
    {
      onSuccess: async (sessionDataResponse: any) => {
        const { access_token, refresh_token } = sessionDataResponse;
        dispatch(setSessionTokenAction(access_token));
        const decoded = jwtDecode(access_token);
        const { sub: userId } = decoded;

        if (userId) {
          dispatch(setUserIdAction(userId));
          handleUserLogin(userId);
        }
        const interceptor = httpClientService.setInterceptor(({ headers, ...restRequestConfig }: any) => {
          return {
            ...restRequestConfig,
            headers: { ...headers, Authorization: `Bearer ${access_token}` },
            'accept-language': i18n.language,
          };
        });

        setCookie(REACT_APP_ACCESS_TOKEN_COOKIE_NAME, access_token, {
          path: '/',
          secure: true,
        });

        setCookie(REACT_APP_REFRESH_TOKEN_COOKIE_NAME, refresh_token, {
          path: '/',
          secure: true,
        });

        dispatch(setIsLoggedInAction(true));

        dispatch(setSessionRefreshTokenAction(false));

        httpClientService.removeInterceptor(interceptor);
      },
      onError: async () => {
        dispatch(setSessionLogoutAction(true));
        handleUserLogout();
        navigate(SSO_PAGE);
      },
    },
  );

  useEffect(() => {
    if (sessionRefreshToken && isLoggedIn) {
      sessionMutate();
    }
  }, [cookies, isLoggedIn, sessionMutate, httpClientService, dispatch, httpClientServiceOAuth, sessionRefreshToken]);
};
