import { useEffect } from 'react';
import { Config } from 'App/config';

export const MatomoTracking = () => {
  useEffect(() => {
    const { REACT_APP_MATOMO_URL } = Config.getAll();

    var _mtm = (window._mtm = window._mtm || []);
    _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });

    const script = document.createElement('script');
    script.async = true;
    script.src = `${REACT_APP_MATOMO_URL}`;

    const firstScript = document.getElementsByTagName('script')[0];

    if (firstScript && firstScript.parentNode) {
      firstScript.parentNode.insertBefore(script, firstScript);
    } else {
      document.head.appendChild(script);
    }

    return () => {
      script.remove();
    };
  }, []);

  return null;
};
