import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import ReactMarkdown from 'react-markdown';

import remarkGfm from 'remark-gfm';
import dompurify from 'dompurify';

import { useTranslation } from 'react-i18next';

import { useUserContext } from 'App/context';

import { Config } from 'App/config';

import { CookiesModal, DeleteDataModal, Layout, Teaser } from 'App/components';

import { CircularLoading, Header, GoBackButton, BackgroundWrapper, Button, ButtonAppearance } from 'components';

import { theme } from 'App/style';

import { Container, Grid, useMediaQuery } from '@mui/material';

import { StaticPageDetailsStyles } from './StaticPageDetails.styles';

import { useStaticPageDetailsData } from './useStaticPageDetailsData';

export const StaticPageDetails = () => {
  const { pageId } = useParams<{
    pageId: string;
  }>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => setIsModalOpen(false);
  const data = useStaticPageDetailsData(pageId as string);
  const { t } = useTranslation(['staticPages']);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.title = 'CO2 Legal';
  });

  const sanitizer = dompurify.sanitize;

  const staticPage: any = data;

  const isLg = useMediaQuery(theme.breakpoints.up('lg'));

  const [{ userId }] = useUserContext();

  const { REACT_APP_MOCK_API } = Config.getAll();

  if (!staticPage?.staticPage?.id) {
    return (
      <Layout
        isFullHeight
        hideContainer
        header={<Header isFixed transparent showDesktopNavigation button={<GoBackButton />} hideRightButton />}
      >
        <Container maxWidth='xl' disableGutters={!isLg}>
          <Grid
            container
            columns={{ md: 8, lg: 12 }}
            justifyContent='center'
            alignItems='center'
            flexDirection={{ sm: 'column', lg: 'row' }}
          >
            <CircularLoading />
          </Grid>
        </Container>
      </Layout>
    );
  }

  const {
    attributes: { title, text },
  } = staticPage.staticPage;

  const LinkRenderer = ({ href, children }: any) => {
    return (
      <a href={href} target='_blank' rel='noopener noreferrer'>
        {children}
      </a>
    );
  };

  return (
    <Layout
      isFullHeight
      hideContainer
      header={<Header transparent showDesktopNavigation button={<GoBackButton />} hideRightButton />}
    >
      <DeleteDataModal onClose={closeModal} isOpen={isModalOpen} />
      <CookiesModal isOpen={isOpen} setIsOpen={setIsOpen} />

      <Teaser hasFixedHeader isAlignCenter title={title} />
      <StaticPageDetailsStyles.StaticPageContainer>
        <BackgroundWrapper>
          <StaticPageDetailsStyles.Wrapper container justifyContent='center'>
            <Grid item md={6}>
              {userId && REACT_APP_MOCK_API !== 'true' && (
                <>
                  <StaticPageDetailsStyles.DeleteDataTitle variant='h4'>
                    {t('staticPages:deleteData:title')}
                  </StaticPageDetailsStyles.DeleteDataTitle>
                  <StaticPageDetailsStyles.DeleteDataTitle variant='body1'>
                    {t('staticPages:deleteData:text')}
                  </StaticPageDetailsStyles.DeleteDataTitle>
                  <StaticPageDetailsStyles.ButtonWrapper>
                    <Button
                      appearance={ButtonAppearance.SECONDARY}
                      isTransparent
                      iconRight
                      text={t('staticPages:deleteData:buttonText')}
                      onClick={() => setIsModalOpen(true)}
                    />
                  </StaticPageDetailsStyles.ButtonWrapper>
                </>
              )}
              <StaticPageDetailsStyles.DeleteDataTitle variant='h4'>
                {t('staticPages:analytics:title')}
              </StaticPageDetailsStyles.DeleteDataTitle>
              <StaticPageDetailsStyles.DeleteDataTitle variant='body1'>
                {t('staticPages:analytics:text')}
              </StaticPageDetailsStyles.DeleteDataTitle>

              <StaticPageDetailsStyles.ButtonWrapper>
                <Button
                  appearance={ButtonAppearance.SECONDARY}
                  isTransparent
                  iconRight
                  text={t('staticPages:analytics:buttonText')}
                  onClick={() => setIsOpen(true)}
                />
              </StaticPageDetailsStyles.ButtonWrapper>
              <StaticPageDetailsStyles.TextBody variant='body1' component='div'>
                <ReactMarkdown remarkPlugins={[[remarkGfm, { singleTilde: false }]]} components={{ a: LinkRenderer }}>
                  {text}
                </ReactMarkdown>
              </StaticPageDetailsStyles.TextBody>
              <StaticPageDetailsStyles.DeleteDataTitle variant='h4'>
                {t('staticPages:license:title')}
              </StaticPageDetailsStyles.DeleteDataTitle>
              <StaticPageDetailsStyles.DeleteDataTitle
                variant='body1'
                dangerouslySetInnerHTML={{ __html: sanitizer(t('staticPages:license:text')) }}
              />
            </Grid>
          </StaticPageDetailsStyles.Wrapper>
        </BackgroundWrapper>
      </StaticPageDetailsStyles.StaticPageContainer>
    </Layout>
  );
};
