// This module will do some more unicorn rainbow magic in the future

declare global {
  interface Window {
    _app_env: ConfigEnv;
    _mtm: any;
  }
}

export interface ConfigEnv {
  [key: string]: string | undefined;
}

export class Config {
  public static getAll(): ConfigEnv {
    // eslint-disable-next-line no-underscore-dangle
    const env = window._app_env || process.env;

    return {
      NODE_ENV: env.NODE_ENV,
      REACT_APP_APP_ID: env.REACT_APP_ID,
      REACT_APP_MOCK_API: env.REACT_APP_MOCK_API,
      REACT_APP_DEMO_API: env.REACT_APP_DEMO_API,
      REACT_APP_ECOLYTIQ_API_AUTH_URL: env.REACT_APP_ECOLYTIQ_API_AUTH_URL,
      REACT_APP_ECOLYTIQ_KEYCLOAK_URL: env.REACT_APP_ECOLYTIQ_KEYCLOAK_URL,
      REACT_APP_ECOLYTIQ_KEYCLOAK_CLIENT_ID: env.REACT_APP_ECOLYTIQ_KEYCLOAK_CLIENT_ID,
      REACT_APP_ECOLYTIQ_CMS_ACCESS_TOKEN: env.REACT_APP_ECOLYTIQ_CMS_ACCESS_TOKEN,
      REACT_APP_ECOLYTIQ_CMS_GRAPHQL_URL: env.REACT_APP_ECOLYTIQ_CMS_GRAPHQL_URL,
      REACT_APP_ECOLYTIQ_API_GRAPHQL_URL: env.REACT_APP_ECOLYTIQ_API_GRAPHQL_URL,
      REACT_APP_ECOLYTIQ_IFRAME_BASEURL: env.REACT_APP_ECOLYTIQ_IFRAME_BASEURL,
      REACT_APP_ECOLYTIQ_CMS_ENV: env.REACT_APP_ECOLYTIQ_CMS_ENV,
      REACT_APP_VISA_COMMUNITY_CODE: env.REACT_APP_VISA_COMMUNITY_CODE,
      REACT_APP_MATOMO_URL: env.REACT_APP_MATOMO_URL,
    };
  }
}
