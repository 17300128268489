import { PropsWithChildren, useEffect, useState } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import { CircularLoading, FooterSection, Grid } from 'components';

import { Container } from '@mui/material';

import { handlePageRoute, handleUserLogin } from 'App/utils';

import { Config } from 'App/config';

import { useTranslation } from 'react-i18next';

import { appConfig } from 'App/appConfig/appConfig';

import { useCookies } from 'react-cookie';

import { RoutePaths } from 'impactApp/routes/routePaths';

import { jwtDecode } from 'jwt-decode';

import {
  setUserIdAction,
  setIsLoggedInAction,
  setSessionRefreshTokenAction,
  useDeps,
  useUserContext,
  resetStateAction,
} from 'App/context';

import { TCModal } from '../TCModal';

import { FeedbackModal } from '../FeedbackModal';

import { CookiesModal } from '../CookiesModal';

import { MatomoTracking } from '../MatomoTracking';

import { useRefreshSessionData } from './useRefreshSessionData';

import { LayoutStyles } from './Layout.styles';

import { useLogoutSessionData } from './useLogoutSessionData';

type LayoutProps = {
  header?: JSX.Element;
  background?: string;
  bgHeight?: string;
  hideContainer?: boolean;
  bgColor?: boolean;
  isFullHeight?: boolean;
};

export const Layout = ({
  background,
  header,
  children,
  bgHeight,
  hideContainer,
  bgColor,
  isFullHeight,
}: PropsWithChildren<LayoutProps>) => {
  const { httpClientService } = useDeps();

  const navigate = useNavigate();

  const { LOGOUT_PAGE, SSO_PAGE, LANDING_PAGE, FEEDBACK_PAGE, HELP_PAGE, STATIC_PAGE } = RoutePaths;

  const { pathname } = useLocation();

  const { REACT_APP_MOCK_API } = Config.getAll();

  const {
    REACT_APP_ANALYTIC_COOKIE_NAME,
    REACT_APP_NECESSARY_COOKIE_NAME,
    REACT_APP_ACCESS_TOKEN_COOKIE_NAME,
    REACT_APP_REFRESH_TOKEN_COOKIE_NAME,
  } = appConfig;

  const [cookies, , removeCookie] = useCookies();

  const necessaryCookie = cookies[REACT_APP_NECESSARY_COOKIE_NAME];

  const [isOpen, setIsOpen] = useState(!necessaryCookie);

  const { i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);

  const [{ sessionRefreshToken }, dispatch] = useUserContext();

  const refreshToken = cookies[REACT_APP_REFRESH_TOKEN_COOKIE_NAME];

  useRefreshSessionData(refreshToken);

  useLogoutSessionData();

  useEffect(() => {
    if (background) {
      const img = new Image();
      img.onload = () => setIsLoading(false);
      img.src = background;
    } else {
      setIsLoading(false);
    }
  }, [background]);

  const location = useLocation();

  useEffect(() => {
    handlePageRoute(location.pathname);
  }, [location]);

  useEffect(() => {
    let interceptor: any;
    let refreshInterceptor: any;
    if (REACT_APP_MOCK_API !== 'true')
      refreshInterceptor = httpClientService.setResponseInterceptor(async (e: any) => {
        const originalRequest = e.config;

        if (e.response.status === 401 && !originalRequest.retry) {
          originalRequest.retry = true;
          dispatch(setSessionRefreshTokenAction(true));
        }
        return Promise.reject(e);
      });

    if (cookies[REACT_APP_ACCESS_TOKEN_COOKIE_NAME] && REACT_APP_MOCK_API !== 'true') {
      interceptor = httpClientService.setInterceptor(({ headers, ...restRequestConfig }: any) => {
        return {
          ...restRequestConfig,
          headers: {
            ...headers,
            Authorization: `Bearer ${cookies[REACT_APP_ACCESS_TOKEN_COOKIE_NAME]}`,
            'accept-language': i18n.language,
          },
        };
      });
      const decoded = jwtDecode(cookies[REACT_APP_ACCESS_TOKEN_COOKIE_NAME]);
      const { sub: userId } = decoded;

      if (userId) {
        dispatch(setUserIdAction(userId));
        handleUserLogin(userId);
      }
      dispatch(setIsLoggedInAction(true));
    } else if (
      location.pathname !== SSO_PAGE &&
      location.pathname !== HELP_PAGE &&
      !location.pathname.includes(STATIC_PAGE) &&
      location.pathname !== LOGOUT_PAGE &&
      location.pathname !== LANDING_PAGE &&
      !cookies[REACT_APP_ACCESS_TOKEN_COOKIE_NAME] &&
      REACT_APP_MOCK_API !== 'true'
    ) {
      removeCookie(REACT_APP_ACCESS_TOKEN_COOKIE_NAME, { path: '/' });
      removeCookie(REACT_APP_REFRESH_TOKEN_COOKIE_NAME, { path: '/' });
      dispatch(resetStateAction());
      navigate(SSO_PAGE);
    }
    return () => {
      if (REACT_APP_MOCK_API !== 'true') {
        httpClientService.removeInterceptor(interceptor);
        httpClientService.removeInterceptor(refreshInterceptor);
      }
    };
  }, [
    LOGOUT_PAGE,
    REACT_APP_ACCESS_TOKEN_COOKIE_NAME,
    REACT_APP_MOCK_API,
    REACT_APP_REFRESH_TOKEN_COOKIE_NAME,
    SSO_PAGE,
    cookies,
    dispatch,
    httpClientService,
    location.pathname,
    navigate,
    removeCookie,
    sessionRefreshToken,
    i18n.language,
    LANDING_PAGE,
    STATIC_PAGE,
    HELP_PAGE,
  ]);

  return isLoading ? (
    <Grid alignItems='center' minHeight='100vh'>
      <CircularLoading />
    </Grid>
  ) : (
    <LayoutStyles $bgHeight={bgHeight} $src={background} $fullheight={isFullHeight} $bgcolor={bgColor}>
      {pathname !== LOGOUT_PAGE && pathname !== SSO_PAGE && !necessaryCookie && (
        <CookiesModal isOpen={isOpen} setIsOpen={setIsOpen} />
      )}
      {header}
      <TCModal />
      <FeedbackModal feedbackUrl={FEEDBACK_PAGE} />
      {hideContainer ? (
        <>
          <Grid component='main' flexDirection='column' flex={isFullHeight ? '1' : '0'}>
            {children}
          </Grid>
          <FooterSection helpPageUrl={HELP_PAGE} />
        </>
      ) : (
        <>
          <Container style={{ flex: isFullHeight ? '1' : '0' }} disableGutters maxWidth='xl'>
            <Grid component='main' flexDirection='column'>
              {children}
            </Grid>
          </Container>
          <FooterSection helpPageUrl={HELP_PAGE} />
        </>
      )}
      {cookies[REACT_APP_ANALYTIC_COOKIE_NAME] === true && <MatomoTracking />}
    </LayoutStyles>
  );
};
